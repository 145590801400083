import React from "react";
import Section10 from "../../components/Section10";
import { Link } from "react-router-dom";

const TermsandCondition = () => {
  return (
    <>
      <div class="container mx-auto px-4 py-24 md:py-36  text-white">
        <h1 class="mb-6 md:mb-8 font md:text-4xl   text-2xl  font-semibold font-jamjuree text-center">
          Terms and Condition
        </h1>

        <p class="text-sm mb-4 border-b border-gray-400  py-2">
          Effective Date: 19-Aug-2024
        </p>

        <h2 class="text-2xl font-bold mb-2 font-jamjuree">
          Acceptance of Terms
        </h2>

        <p class="mb-10 text-sm font-popin tracking-wide">
          By accessing or using our website, you agree to abide by these Terms
          and Conditions and all applicable laws and regulations.
        </p>

        <h2 class="text-2xl font-bold mb-2 font-jamjuree">
          Intellectual Property
        </h2>

        <p class="mb-10 text-sm font-popin tracking-wide">
          The content and materials on robx.ai, including but not limited to
          text, graphics, logos, images, and software, are the property of robx
          and are protected by applicable intellectual property laws.
        </p>

        <h2 class="text-2xl font-bold mb-2 font-jamjuree">
          Disclaimer of Liability
        </h2>

        <p class="mb-10 text-sm font-popin tracking-wide">
          To the fullest extent permitted by law, Robx.ai and its affiliates
          shall not be liable for any indirect, incidental, special, or
          consequential damages arising out of or in connection with your use of
          the Site or any products or services purchased through the Site.
        </p>

        <h2 class="text-2xl font-bold mb-2 font-jamjuree">
          Changes to Terms and Conditions
        </h2>

        <p class="mb-10 text-sm font-popin tracking-wide">
          Robx.ai reserves the right to modify or replace these Terms and
          Conditions at any time. Your continued use of the website after any
          changes signify your acceptance of those modifications.
        </p>
        <h2 class="text-2xl font-bold mb-2 font-jamjuree">Privacy Policy</h2>

        <p class="mb-10 text-sm font-popin tracking-wide">
          Please refer to our{" "}
          <span className="text-blue-700 hover:underline">
            <Link to="/privacypolicy"> Privacy Policy</Link>
          </span>{" "}
          for information on how we collect, use, and protect your personal
          information.
        </p>
        <h2 class="text-2xl font-bold mb-2 font-jamjuree">Contact Us</h2>

        <p class="mb-4 text-sm font-popin tracking-wide">
          If you have any questions or concerns about our Privacy Policy, please
          contact us at
          <span className="text-blue-700 hover:underline">
            {" "}
            <a href="mailto:info@robx.ai"> info@robx.ai</a>
          </span>
        </p>
      </div>
      <Section10 />
    </>
  );
};

export default TermsandCondition;

import "./App.css";
import Router from "./config/router";

import Footer from "./components/Footer";
// import Navbar from "./components/Navbar";
// import Section1 from "./components/Section1";
// import Section2 from "./components/Section2";
// import Section3 from "./components/Section3";
// import Section4 from "./components/Section4";
// import Section5 from "./components/Section5";
// import Section6 from "./components/Section6";
// import Section7 from "./components/Section7";
// import Section8 from "./components/Section8";
// import Section9 from "./components/Section9";
// import Section10 from "./components/Section10";

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
